<template>
  <div id="token-modal-display">
    <div class="modal-button-container">
      <button type="button" class="modal-button" @click="closeModal()">
        <svg class="modal-x-svg" xmlns="http://www.w3.org/2000/svg" viewBox="-25 -25 80 80" width="50" overflow="visible" stroke="black" stroke-width="10" stroke-linecap="round">
          <line x1="0" y1="0" x2="50" y2="50" />
          <line x1="50" y1="0" x2="0" y2="50" />
        </svg>
      </button>
    </div>
    <div class="token-image-container">
      <iframe
        class="token-image iframe"
        :src="iframeSrc"
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>

export default {
  name: "TokenModalDisplay",
  props: {
    // tokenData: Object,
    tokenId: String,
    // contractAddress: String,
    collectionId: String,
    // blockchainId: String,
    // contract: Object,
    // connectedAccount: String,
    iframeSrc: String,
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key == 'Escape') {
        this.closeModal()
      }
    });
  },
  methods: {
    closeModal() {
      this.$parent.closeTokenModal();
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#token-modal-display {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.modal-button-container {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
}

.token-image-container {
  margin: auto;
  width: 90vw;
  height: 90vw;
  max-width: 500px;
  max-height: 500px;
  text-align: center;
  background: #f0f0f0;
  display: inline-block;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #444;
}

.iframe {
  /* width: 200px;
  height: 200px; */
  background: url("/spinner.gif") 50% 50% no-repeat;
}

.modal-button {
  border: 0px;
  background: rgba(0, 0, 0, 0);
}
.modal-button:hover {
  cursor: pointer;
}
.modal-x-svg {
  width: 20px;
  height: auto;
}


</style>
