<template>
  <div>
    <div class="banner-container">
      <div class="banner-item left">Blockchain<br><b>{{ blockchainName }}</b></div>
      <!-- <div class="banner-item center">AtomicNFTs.xyz</div> -->
      <div class="banner-item center">{{ collectionName }}</div>
      <div class="banner-item right">First Mint<br><b>{{ firstMintDate }}</b></div>
    </div>
    <hr>
  </div>
</template>

<script>


export default {
  name: 'Header',
  props: {
    blockchainName: String,
    collectionName: String,
    firstMintDate: String
  },
  methods: {
    formatDate(date) {
      return `${date.getDate()} ${date.toLocaleDateString('en-US', {month: 'long'}).toUpperCase()} ${date.getFullYear()}`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.banner-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
}

.banner-item {
}

.left {
  text-align: left;
  flex-grow: 1;
  flex-basis: 0;
}
.center {
  max-width: 50%;
  text-align: center;
  /* font-size: 16px; */
  letter-spacing: 3px;
}

.right {
  text-align: right;
  flex-grow: 1;
  flex-basis: 0;
}

hr {
  border: 1px solid black;
}

</style>
