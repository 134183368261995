<template>
  <button :class="mintButtonClassName()" v-on:click="mintTokenButton()">
    <div class="mint-text">{{ mintMsg }}</div>
  </button>
</template>

<script>
import $backend from "@/backend.js";

export default {
  name: "MintAtomicDecayButton",
  props: {
    collectionId: String,
    decaysContract: Object,
    bohrDiagramsContract: Object,
    burnTokenId: Number,
    connectedAccount: String,
    polygonNetwork: String,
  },
  methods: {
    async sleep(ms) {
      return await new Promise((resolve) => setTimeout(resolve, ms));
    },
    mintButtonClassName() {
      if (this.isMinting) {
        return "button mint minting";
      } else if (this.isMinted) {
        return "button mint minted";
      } else {
        return "button mint new";
      }
    },
    async mintTokenButton() {
      if (this.isMinting || this.isMinted) {
        // window.open(`https://rinkeby.etherscan.io/tx/${this.tx.hash}`)
        let networkSubdomain = this.$props.polygonNetwork === "testnet" ? "mumbai." : "";
        window.open(`https://${networkSubdomain}polygonscan.com/tx/${this.tx.hash}`);
      } else {
        if (!this.$props.decaysContract) {
          await this.$parent.connectWallet();
        }
        // await this.testMintToken();
        await this.mintToken();
      }
    },
    async testMintToken() {
      // if (!this.$props.burnTokenId) {
      //   this.mintMsg = "Enter Token ID for Atomic Bohr Diagram to Burn";
      //   return;
      // } else if (!Number.isInteger(this.$props.burnTokenId)) {
      //   this.mintMsg = "Burn Token ID must be an integer";
      // }
      // const numBurnTokens = await $backend.web3GetERC1155AccountBalance(
      //   this.$props.bohrDiagramsContract,
      //   this.tx.from,
      //   this.$props.burnTokenId
      // );
      // if (!(numBurnTokens > 0)) {
      //   this.mintMsg = `No Atom #${this.$props.burnTokenId} tokens, cannot mint a Decay`;
      //   return;
      // }
      // this.tx = await $backend.web3Mint(
      //   this.$props.decaysContract,
      //   this.$props.collectionId,
      //   this.$props.tokenId
      // );
      // console.log(this.tx);
      // if (!this.tx) {
      //   let msg = "Mint failed";
      //   this.mintMsg = msg;
      //   console.error(msg);
      //   return;
      // } else if (!this.tx.from) {
      //   let msg = "Mint failed";
      //   this.mintMsg = msg;
      //   console.error(msg);
      //   return;
      // }
      // this.isMinting = true;
      // this.mintMsg = "View Txn on polygonscan ⏳ Waiting for Confirmations";
      // const receipt = await this.tx.wait(); // wait for tx to be mined
      // console.log(receipt);
      // if (!receipt.status) {
      //   let msg = "Mint failed";
      //   this.mintMsg = msg;
      //   console.error(msg);
      // }
      console.log(this.$props.burnTokenId);
      if (!this.$props.burnTokenId) {
        this.mintMsg = "Enter Token ID for Atomic Bohr Diagram to Burn";
        return;
      } else if (!Number.isInteger(this.$props.burnTokenId)) {
        this.mintMsg = "Burn Token ID must be an integer";
        return;
      } else if (this.$props.burnTokenId < 1 || this.$props.burnTokenId > 118) {
        this.mintMsg = "Burn Token ID must be between 1 and 118";
        return;
      }
      this.isMinting = true;
      this.tx = { hash: null };
      // $backend.generateDecayNFT(this.tx.hash);
      this.isMinting = false;
      this.isMinted = true;
      this.mintMsg = "Test passed ✔️";
      // this.mintMsg = "View Txn on polygonscan ✔️ Confirmed!";
    },
    async mintToken() {
      if (!this.$props.burnTokenId) {
        this.mintMsg = "Enter Token ID for Atomic Bohr Diagram to Burn";
        return;
      } else if (!Number.isInteger(this.$props.burnTokenId)) {
        this.mintMsg = "Burn Token ID must be an integer";
        return;
      } else if (this.$props.burnTokenId < 1 || this.$props.burnTokenId > 118) {
        this.mintMsg = "Burn Token ID must be between 1 and 118";
        return;
      }
      const numBurnTokens = await $backend.web3ERC1155GetAccountBalance(
        this.$props.bohrDiagramsContract,
        this.$props.connectedAccount,
        this.$props.burnTokenId
      );
      if (!(numBurnTokens > 0)) {
        this.mintMsg = `No Atom #${this.$props.burnTokenId} tokens, cannot mint a Decay`;
        return;
      }
      this.tx = await $backend.web3Mint(
        this.$props.decaysContract,
        this.$props.collectionId,
        this.$props.burnTokenId
      );
      console.log(this.tx);
      if (!this.tx) {
        let msg = "Mint failed ✕";
        this.mintMsg = msg;
        console.error(msg);
        return;
      } else if (this.tx === 'transaction underpriced') {
        let msg = "Mint failed ✕ Not enough gas ⛽ Please try again.";
        this.mintMsg = msg;
        console.error(msg);
        return;
      } else if (!this.tx.from) {
        let msg = "Mint failed ✕";
        this.mintMsg = msg;
        console.error(msg);
        return;
      }
      this.isMinting = true;
      this.mintMsg = "View Txn on polygonscan ⏳ Waiting for Confirmations";
      const receipt = await this.tx.wait(); // wait for tx to be mined
      console.log(receipt);
      if (!receipt.status) {
        let msg = "Mint failed ✕";
        this.mintMsg = msg;
        console.error(msg);
      }
      $backend.generateDecayNFT(this.tx.hash);
      await this.$parent.triggerRefreshWeb3TokenData();
      await this.$parent.setAccountBalance(this.tx.from);
      this.isMinting = false;
      this.isMinted = true;
      this.mintMsg = "View Txn on polygonscan ✔️ Confirmed! ⏳ NFT will generate within 10 min";
    },
  },
  watch: {
    burnTokenId: function () {
      this.mintMsg = "Burn & Mint";
      this.isMinting = false;
      this.isMinted = false;
    },
  },
  data() {
    return {
      mintMsg: "Burn & Mint",
      isMinting: false,
      isMinted: false,
      tx: null,
    };
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button.mint {
  cursor: default;
  border: 1px solid #ccc;
  width: 100%;
  vertical-align: middle;
}
.button.mint:hover {
  background: rgba(212, 0, 0, 0.336);
}
.button.mint.new {
  background: rgba(212, 0, 0, 0.205);
}
.button.mint.minting {
  background: rgba(218, 204, 8, 0.384);
}
.button.mint.minted {
  background: rgba(0, 212, 0, 0.205);
}
</style>
