<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  font-family: "Computer Modern", sans-serif;
  /* font-size: 12px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
h1 {
  font-size: 30px;
}

.box {
  display: inline-block;
  outline: none;
  padding: 0.5em 1em;
  margin-top: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #444;
  /* font-size: 0.9em; */
  background-color: white;
}
.button {
  display: inline-block;
  outline: none;
  padding: 0.5em 1em;
  margin-top: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #444;
  font-size: 1em;
  background-color: white;
  transition: all 0.2s;
}
.button:hover {
  background-color: #ccc;
  color: black;
}
.button:active,
.button.selected {
  background-color: #444;
  border: 1px solid #444;
}
.button.selected {
  color: white;
}
.button:active {
}
</style>
