<template>
  <div class="tos-container">
    <div class="tos-content">
      <p>
        By proceeding to use this site (connecting your wallet, minting NFTs)
        you acknowledge and accept the terms and conditions of the
        <router-link to="/legal-disclaimer" target="_blank"
          >Legal Disclaimer</router-link
        >.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfServiceNotice",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tos-container {
  padding: 10px 30px;
  width: 370px;
  max-width: 70%;
  /* font-size: 12px; */
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: rgba(255, 17, 0, 0.1);
}
.tos-content {
  text-align: center;
  margin: 0;
}
</style>
