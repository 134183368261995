import Vue from "vue";
import App from "./App.vue";
import VueHead from "vue-head";
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";

import AtomicNFTs from "./components/AtomicNFTs.vue";
import LegalDisclaimer from "./components/LegalDisclaimer.vue";
import ErrorPage from "./components/Error.vue";

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// library.add(faExternalLinkAlt)

// Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueHead);
Vue.use(VueRouter);
Vue.config.productionTip = false;

const router = new VueRouter({
  routes: [
    {
      path: "/",
      name: "AtomicNFTs",
      component: AtomicNFTs,
    },
    {
      path: "/atomic-bohr-diagrams",
      name: "AtomicNFTs",
      component: AtomicNFTs,
    },
    {
      path: "/atomic-decays",
      name: "AtomicNFTs",
      component: AtomicNFTs,
    },
    {
      path: "/legal-disclaimer",
      name: "LegalDisclaimer",
      component: LegalDisclaimer,
    },
    {
      path: "/error",
      name: "ErrorPage",
      component: ErrorPage,
    },
    {
      path: "*",
      componen: ErrorPage,
    },
  ],
  mode: "history",
});

Vue.use(
  VueGtag,
  {
    config: { id: "G-7C0ZTEC1VR" },
  },
  router
);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
