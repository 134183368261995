<template>
  <div class="main">
    <a href="https://atomicnfts.xyz/">
      <img
        class="header-logo"
        src="https://atomicnfts.xyz/atomic_nfts_transparent.png"
        alt="AtomicNFTs.xyz"
      />
    </a>
    <h1>Error 404: Page not found</h1>
    <h3>We can't tell if the page you requested is a particle or a wave</h3>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
};
</script>

<style scoped>
.main {
  width: 500px;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
}
.header-logo {
  width: 400px;
  max-width: 60%;
}
</style>

