<template>
  <div class="main">
    <CookiesAlert />
    <Header
      :blockchainName="
        tokenData[polygonNetwork][collectionId][blockchainId].name
      "
      :collectionName="tokenData[polygonNetwork][collectionId].name"
      :firstMintDate="
        tokenData[polygonNetwork][collectionId][blockchainId].firstMintDate
      "
    />
    <CollectionHeader
      :collectionName="tokenData[polygonNetwork][collectionId].name"
      :collectionDesc="tokenData[polygonNetwork][collectionId].nft_desc"
    />
    <TermsOfServiceNotice />
    <TokenModalDisplay
      v-if="tokenModalVisible"
      :tokenId="tokenModalTokenId"
      :collectionId="collectionId"
      :iframeSrc="tokenModalIframeSrc"
    />
    <div class="wallet-connect-container">
      <div class="wallet-connect-network-container">
        <div v-if="!web3Account">
          <button
            class="button wallet connect-action"
            v-on:click="connectWallet"
          >
            Connect Wallet
          </button>
          <div class="button wallet-error-message" v-if="web3Error">
            {{ web3Error }}
          </div>
        </div>
        <div v-else class="button wallet connect-display">
          {{ web3AccountDisplay() }}<br />
          {{ web3AccountBalance }} ATOMS<br />
          {{ `(${web3BohrAtomsBalance} Bohr, ${web3DecayAtomsBalance} Decay)`
          }}<br />
          <div
            v-if="web3AccountBalance"
            class="button opensea"
            v-on:click="openSeaLinkButton()"
          >
            <div class="mint-text">View on OpenSea</div>
          </div>
        </div>
        <!-- <div class="button network-select"> -->
        <select v-model="polygonNetwork" class="button select network-select">
          <option value="mainnet">Polygon Mainnet</option>
          <option value="testnet">Mumbai Testnet</option>
        </select>
        <!-- </div> -->
      </div>
      <div
        v-if="collectionId == 'atomic-decays' && polygonNetwork == 'mainnet'"
        class="mint"
      >
        <MintAtomicDecayButton
          :collectionId="collectionId"
          :decaysContract="web3Contracts['atomic-decays']"
          :bohrDiagramsContract="web3Contracts['atomic-bohr-diagrams']"
          :burnTokenId="burnTokenId"
          :connectedAccount="web3Account"
          :polygonNetwork="polygonNetwork"
        />
        <input
          class="button input"
          v-model.number="burnTokenId"
          type="number"
          min="1"
          max="118"
          placeholder="Enter Atom # to Burn"
        />
      </div>
    </div>
    <div class="collection-swapper-container">
      <div class="collection-swapper">
        <button
          :class="collectionButtonClass('atomic_bohr_diagrams')"
          v-on:click="updateCollection('atomic-bohr-diagrams')"
        >
          Atomic Bohr Diagrams
        </button>
        <button
          :class="collectionButtonClass('atomic_decays')"
          v-on:click="updateCollection('atomic-decays')"
        >
          Atomic Decays
        </button>
      </div>
    </div>
    <!-- <div class="collection-swapper-container">
      <div class="collection-swapper">
        <button 
          :class="blockchainButtonClass('ethereum')"
          v-if="tokenData[collectionId].ethereum"
          v-on:click="updateBlockchain('ethereum')"
        >
          Ethereum
        </button>
        <button
          :class="blockchainButtonClass('polygon')"
          v-if="tokenData[collectionId].polygon"
          v-on:click="updateBlockchain('polygon')"
        >
          Polygon
        </button>
        <button
          :class="blockchainButtonClass('solana')"
          v-if="tokenData[collectionId].solana"
          v-on:click="updateBlockchain('solana')"
        >
          Solana
        </button>
      </div>
    </div> -->
    <!-- <OpenSeaCollectionFeed :v-if="isOpenSeaCollection()" :tokenData="tokenData[this.collectionId][this.blockchainId]" :collectionId="collectionId" :blockchainId="blockchainId"/> -->
    <Web3CollectionFeed
      if="isWeb3Collection()"
      :tokenData="
        tokenData[polygonNetwork][this.collectionId][this.blockchainId]
      "
      :collectionId="collectionId"
      :blockchainId="blockchainId"
      :contract="web3Contracts[this.collectionId]"
      :connectedAccount="web3Account"
      :tokenCanMint="tokenCanMint[collectionId]"
      :refreshWeb3TokenData="refreshWeb3TokenData"
      :polygonNetwork="polygonNetwork"
    />
    <CollectionFooter
      :collectionName="tokenData[polygonNetwork][collectionId].name"
      :collectionDesc="tokenData[polygonNetwork][collectionId].technical_desc"
    />
    <!-- <div class="pad"></div> -->
    <Footer
      :contractAddress="
        tokenData[polygonNetwork][collectionId][blockchainId].contractAddress
      "
      :polygonNetwork="polygonNetwork"
    />
  </div>
</template>

<script>
import CookiesAlert from "@/components/CookiesAlert.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CollectionHeader from "@/components/CollectionHeader.vue";
import TermsOfServiceNotice from "@/components/TermsOfServiceNotice.vue";
import CollectionFooter from "@/components/CollectionFooter.vue";
// import OpenSeaCollectionFeed from '@/components/OpenSeaCollectionFeed.vue'
import Web3CollectionFeed from "@/components/Web3CollectionFeed.vue";
import MintAtomicDecayButton from "@/components/MintAtomicDecayButton.vue";
import TokenModalDisplay from "@/components/TokenModalDisplay.vue";
// import localTokenData from "../../public/token_data.json";
import $backend from "@/backend.js";

export default {
  name: "AtomicNFTs",
  components: {
    CookiesAlert,
    Header,
    Footer,
    CollectionHeader,
    CollectionFooter,
    Web3CollectionFeed,
    TokenModalDisplay,
    MintAtomicDecayButton,
    TermsOfServiceNotice,
    // OpenSeaCollectionFeed
  },
  head: {
    title: function () {
      return {
        inner: "Atomic NFTs | Collect and trade the elements of the universe",
      };
    },
    meta: function () {
      return [
        {
          id: "description",
          name: "description",
          content:
            "Limited collections of Atomic NFT digital art tokens on Polygon, inspired by visionary physicists who constructed mathematical theories to describe our universe.",
        },
        {
          id: "og-image",
          property: "og:image",
          content: "https://atomicnfts.xyz/atomic_nfts.png",
        },
      ];
    },
  },
  methods: {
    async loadContracts() {},
    async connectWallet() {
      this.web3Account = "";
      this.web3Error = "";
      this.web3AccountBalance = 0;
      this.web3BohrAtomsBalance = 0;
      this.web3DecayAtomsBalance = 0;
      this.web3Contracts = {
        "atomic-bohr-diagrams": null,
        "atomic-decays": null,
      };

      let { account, msg } = await $backend.web3ConnectWallet(
        this.polygonNetwork
      );
      this.web3Account = account;
      this.web3Error = msg;

      let contracts = await $backend.web3GetContracts(
        this.tokenData[this.polygonNetwork]["atomic-bohr-diagrams"][
          this.blockchainId
        ].contractAddress,
        this.tokenData[this.polygonNetwork]["atomic-decays"][this.blockchainId]
          .contractAddress
      );
      this.web3Contracts = contracts;

      await this.setAccountBalance(account);

      // if (this.web3Account) {
      //   this.$emit('web3Available')
      // }
    },
    openSeaLinkButton() {
      if (this.polygonNetwork === "testnet") {
        window.open(
          `https://testnets.opensea.io/${this.web3Account}/testnet-${this.collectionId}`
        );
      } else if (this.polygonNetwork === "mainnet") {
        window.open(
          `https://opensea.io/${this.web3Account}/${this.collectionId}`
        );
      } else {
        console.error(
          "No opensea collection for network: " + this.polygonNetwork
        );
      }
    },
    async setAccountBalance(account) {
      [
        this.web3AccountBalance,
        this.web3BohrAtomsBalance,
        this.web3DecayAtomsBalance,
      ] = await $backend.web3GetAccountBalances(
        this.web3Contracts["atomic-bohr-diagrams"],
        this.web3Contracts["atomic-decays"],
        account
      );
      console.log(this.web3AccountBalance);
      console.log(this.web3BohrAtomsBalance);
      console.log(this.web3DecayAtomsBalance);
    },
    async loadTokenData() {
      this.tokenData = await $backend.GET("/token_data.json");
      // this.tokenData = localTokenData;
      this.$emit("tokenDataLoaded");
    },
    showTokenModal(tokenId, iframeSrc) {
      this.tokenModalVisible = true;
      this.tokenModalTokenId = tokenId;
      this.tokenModalIframeSrc = iframeSrc;
    },
    closeTokenModal() {
      this.tokenModalVisible = false;
    },
    updateCollection(collectionId) {
      this.collectionId = collectionId;
      let p = this.$route.path.substring(1);
      if (p.length > 0 && p != collectionId) {
        this.$router.push({ path: collectionId });
      }
    },
    updateBlockchain(blockchainId) {
      this.blockchainId = blockchainId;
    },
    collectionButtonClass(collectionId) {
      let className = "button";
      if (collectionId === this.collectionId) {
        className += " selected";
      }
      return className;
    },
    web3AccountDisplay() {
      if (this.web3Account) {
        return (
          this.web3Account.slice(0, 4) +
          "..." +
          this.web3Account.slice(
            this.web3Account.length - 4,
            this.web3Account.length
          )
        );
      }
    },
    // blockchainButtonClass(blockchainId) {
    //   let className = "button"
    //   if (blockchainId === this.blockchainId) {
    //     className += " selected"
    //   }
    //   return className
    // },
    isOpenSeaCollection() {
      // if ((["ethereum"].includes(this.blockchainId)) & (["atomic_bohr_diagrams"].includes(this.collectionId))) {
      //   return true
      // } else {
      //   return false
      // }
      return false;
    },
    isWeb3Collection() {
      return true;
    },
    triggerRefreshWeb3TokenData() {
      this.refreshWeb3TokenData += 1;
    },
  },
  async mounted() {
    await this.loadTokenData();
  },
  updated() {
    if (
      ["/atomic-bohr-diagrams", "/atomic-decays"].includes(this.$route.path)
    ) {
      // this.collectionId = this.$route.path.substring(1);
      this.updateCollection(this.$route.path.substring(1));
    }
  },
  watch: {
    polygonNetwork: async function () {
      if (this.web3Account) {
        // only prompt connect if already connected
        await this.connectWallet();
      }
    },
  },
  data() {
    return {
      blockchainId: "ethereum",
      collectionId: "atomic-bohr-diagrams",
      collectionVersion: 1,
      tokenData: {
        testnet: {
          "atomic-bohr-diagrams": { ethereum: {} },
          "atomic-decays": { ethereum: {} },
        },
        mainnet: {
          "atomic-bohr-diagrams": { ethereum: {} },
          "atomic-decays": { ethereum: {} },
        },
      },
      tokenCanMint: {
        "atomic-bohr-diagrams": true,
        "atomic-decays": false,
      },
      web3Account: "",
      web3Error: "",
      web3AccountBalance: 0,
      web3BohrAtomsBalance: 0,
      web3DecayAtomsBalance: 0,
      web3Contracts: { "atomic-bohr-diagrams": null, "atomic-decays": null },
      burnTokenId: null,
      refreshWeb3TokenData: 0,
      tokenModalVisible: false,
      tokenModalTokenId: null,
      tokenModalIframeSrc: "",
      polygonNetwork: "mainnet",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  max-width: 1000px;
  min-height: 100vh;
  margin: 0 auto;
}

.hidden {
  display: none;
}
.pad {
  padding-top: 50px;
}

.collection-swapper-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px 0;
  align-items: stretch;
}

.collection-swapper {
}

.button.wallet {
  background: rgba(0, 212, 0, 0.205);
}
.button.wallet:hover {
  background: rgba(0, 212, 0, 0.336);
}

.button.wallet.connect-display {
  line-height: 20px;
  background: #f0f0f0;
}
.button.wallet.connect-display:hover {
  background: #f0f0f0;
}

.select.network-select {
  border: 0px solid transparent;
}

.mint {
  width: 200px;
}

.wallet-connect-network-container {
  width: 200px;
}

.input {
  width: 100%;
  display: block;
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.button.opensea {
  cursor: default;
  border: 1px solid #ccc;
  /* color: #444; */
  /* text-decoration: none; */
  background: rgba(0, 212, 212, 0.205);
}
.button.opensea:hover {
  /* color: black; */
  background: rgba(0, 212, 212, 0.336);
}

.wallet-error-message {
  background: rgba(212, 14, 0, 0.205);
}

.wallet-connect-container {
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collection-swapper:after {
  content: "";
  display: block;
  clear: both;
}

.collection-swapper .button {
  float: left;
  border-radius: 0;
  margin-left: 0;
  margin-right: -1px;
}

.collection-swapper .button:first-child {
  border-radius: 4px 0 0 4px;
}
.collection-swapper .button:last-child {
  border-radius: 0 4px 4px 0;
}
.collection-swapper .button:only-child {
  border-radius: 4px 4px 4px 4px;
}
</style>
