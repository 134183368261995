<template>
  <div class="collection-header">
    <div class="hidden">
      <h1>AtomicNFTs | {{ collectionName }}</h1>
    </div>
    <!-- <div class="collection-name"><p>{{ collectionName }}</p></div> -->
    <div class="collection-name">
      <img
        class="header-logo"
        src="https://atomicnfts.xyz/atomic_nfts_transparent.png"
        alt="atomicnfts.xyz"
      />
    </div>
    <div class="social-link-container">
      <a
        class="social-button"
        href="https://twitter.com/AtomicNFTs_xyz"
        target="_blank"
      >
        <img class="social-button-image" src="/assets/twitter.png" alt="" />
      </a>
      <a
        class="social-button"
        href="http://discord.gg/mjk8a76n"
        target="_blank"
      >
        <img class="social-button-image" src="/assets/discord.png" alt="" />
      </a>
    </div>
    <div class="collection-desc"><p v-html="collectionDesc"></p></div>
  </div>
</template>

<script>
export default {
  name: "CollectionHeader",
  props: {
    collectionName: String,
    collectionDesc: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.collection-header {
}

.header-logo {
  width: 400px;
  max-width: 60%;
}

.collection-name {
  font-size: 40px;
  margin: auto;
}

.collection-desc {
  width: 500px;
  max-width: 70%;
  /* font-size: 12px; */
  margin: auto;
  text-align: left;
  /* padding-bottom: 10px; */
}
.hidden {
  display: none;
}

.social-link-container {
  display: flex;
  justify-content: center;
}

.social-button {
  width: 50px;
  height: 50px;
  padding: 10px;
}

.social-button-image {
  max-width: 100%;
}
</style>
