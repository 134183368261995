<template>
  <div id="token-display"
  >
    <!-- <div
      v-if="$props.tokenData.num_owned_by_connected_addr"
      class="token-badge"
    >
      <img :src="badge" alt="">
      <img src="/favicon.png" alt="" class="token-badge-image" />
    </div> -->
    <div class="token-container">
      <div class="display-container">
        <div
          class="token-image-container"
          v-on:mouseover="hoverOnItem()"
          v-on:mouseout="hoverOffItem()"
          v-on:click="showTokenModal()"
        >
          <!-- <div v-on:click="openTokenAnimation()"> -->
            <!-- Allow clicks to display token modal instead of iframe interaction -->
            <div class="iframe-overlay"></div>
            <img
              class="token-image img"
              v-if="!showAnimation"
              :src="tokenImageSrc()"
              :alt="$props.tokenData.name"
              width="100%"
              height="100%"
            />
            <iframe
              class="token-image iframe"
              :src="tokenIframeSrc()"
              v-if="showAnimation"
              width="100%"
              height="100%"
              frameborder="0"
              allowfullscreen
            ></iframe>
        </div>
        <div class="token-info-container" v-on:mouseover="hoverOffItem()">
          <div class="token-actions">
            <MintAtomicBohrDiagramButton
              v-if="collectionId == 'atomic-bohr-diagrams' && this.$props.polygonNetwork === 'mainnet'"
              :collectionId="collectionId"
              :tokenId="tokenId"
              :contract="contract"
            />
            <div class="button opensea" v-on:click="openSeaLinkButton()">
              <div class="mint-text">View on OpenSea</div>
            </div>
            <div class="box info">
              <!-- v-if=""> -->
              <div class="info-name">{{ $props.tokenData.name }}</div>
              <div v-if="collectionId == 'atomic-bohr-diagrams'">
                <div v-if="$props.tokenData.mint_price">
                  {{ this.$props.tokenData.num_minted }}/100 minted &nbsp;
                  {{ this.$props.tokenData.num_burned }}/{{
                    this.$props.tokenData.num_minted
                  }}
                  burned
                  <!-- <div>{{ this.$props.tokenData.num_minted }}/100 minted</div>
                  <div>{{ this.$props.tokenData.num_burned }}/{{ this.$props.tokenData.num_minted }} burned</div> -->
                </div>
                <div v-else>Please connect MetaMask to use this DApp</div>
                <div v-if="$props.tokenData.mint_price" class="into-mintprice">
                  Next {{ numTokensMintingAtCurrentPrice() }} mint for
                  {{ $props.tokenData.mint_price }} MATIC
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $backend from '@/backend.js'
// import badgeSvg from "@/assets/badge.svg";
import MintAtomicBohrDiagramButton from "@/components/MintAtomicBohrDiagramButton.vue";

export default {
  name: "TokenDisplay",
  props: {
    tokenData: Object,
    tokenId: String,
    contractAddress: String,
    collectionId: String,
    blockchainId: String,
    contract: Object,
    connectedAccount: String,
    polygonNetwork: String,
  },
  components: {
    MintAtomicBohrDiagramButton,
  },
  created() {
    // window.document.getElementById(`frame-${this.tokenId}`).contentDocument.addEventListener("click", () => {
    //   console.log("click")
    // })
    // window.addEventListener("click", () => {
    //   console.log("clicked 1");
    //   console.log(document.activeElement);
    //   if (document.activeElement.tagName === "IFRAME") {
    //     console.log("clicked 2");
    //   }
    // });
  },
  methods: {
    // async sleep(ms) {
    //   return await new Promise((resolve) => setTimeout(resolve, ms));
    // },
    showTokenModal() {
      this.$parent.$parent.showTokenModal(this.tokenId, this.tokenIframeSrc());
    },
    openSeaLinkButton() {
      if (this.$props.polygonNetwork === "testnet") {
        window.open(`https://testnets.opensea.io/assets/mumbai/${this.$props.contractAddress}/${this.$props.tokenId}`);
      } else if (this.$props.polygonNetwork === "mainnet") {
        window.open(`https://opensea.io/assets/matic/${this.$props.contractAddress}/${this.$props.tokenId}`);
      } else {
        console.error("No opensea collection for network: " + this.$props.polygonNetwork)
      }
    },
    // async checkIfOwned() {
    //   this.isOwned = await $backend.web3GetAccountBalance(this.$props.contract, this.$props.connectedAccount)
    //   console.log("isOwned")
    //   console.log(this.isOwned)
    // },
    numTokensMintingAtCurrentPrice() {
      let numMinted = this.$props.tokenData.num_minted;
      // get the number remaining until the price goes up
      // e.g. 49 minted => 10 - 9 = 1 remaining
      let res = 10 - Number(String(numMinted).padStart(2, 0)[1]);
      return res;
    },
    // async numMinted() {
    //   return (await $backend.web3GetNumMinted(this.$props.contract, this.$props.tokenId))
    // },
    // async numBurned() {
    //   return (await $backend.web3GetNumBurned(this.$props.contract, this.$props.tokenId))
    // },
    tokenImageSrc() {
      let networkFolder = this.$props.polygonNetwork === "testnet" ? 'testnet-nft' : 'nft';
      return `/assets/${networkFolder}/${this.$props.collectionId}/image/${this.$props.tokenId}`;
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    showLoopAnimation() {
      if ((this.$props.collectionId == 'atomic-decays') && (!(this.isMobile()))) {
        return true;
      } else {
        return false;
      }

    },
    tokenIframeSrc() {
      let networkFolder = this.$props.polygonNetwork === "testnet" ? 'testnet-nft' : 'nft';
      let animationFolder = this.showLoopAnimation() ? 'loop-animation' : 'animation';
      return `/assets/${networkFolder}/${this.$props.collectionId}/${animationFolder}/${this.$props.tokenId}`;
    },
    // openTokenAnimation() {
    //   window.open(this.tokenIframeSrc());
    // },
    hoverOnItem() {
      this.showAnimation = true;
    },
    hoverOffItem() {
      this.showAnimation = false;
    },
    ethMintPrice() {
      if (this.$props.tokenData.mint_price) {
        return `for ${this.$props.tokenData.mint_price} ETH`;
      } else {
        return "";
      }
    },
  },
  // async mounted() {
  //   this.badge = badgeSvg;
  // },
  data() {
    return {
      showAnimation: false,
      mintMsg: "Mint",
      isMinting: false,
      isMinted: false,
      tx: null,
      // badge: null,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#token-display {
  width: 300px;
  max-width: 90%;
  margin: auto;
  text-align: center;
  position: relative;
}

.token-image-container {
  margin: auto;
  text-align: center;
  width: 200px;
  height: 200px;
  position: relative;
}

/* .token-info-container {
  height: 900px;
} */

.token-image {
  width: 200px;
  height: 200px;
}

/* .token-badge {
  position: absolute;
  width: 32px;
  top: -5px;
  right: -10px;
}
.token-badge-image {
  width: 100%;
} */

.iframe {
  width: 200px;
  height: 200px;
  background: url("/spinner.gif") 50% 50% no-repeat;
}

.iframe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.token-container {
  background: #f0f0f0;
  display: inline-block;
  outline: none;
  padding: 0.5em 1em;
  margin-top: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #444;
  /* font-size: 0.9em; */
  transition: 0.3s;
}

/* .token-container:hover {
  border: 2.5px solid black;
} */

.token-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 10px;
}

.button.opensea {
  cursor: default;
  border: 1px solid #ccc;
  /* color: #444; */
  /* text-decoration: none; */
  background: rgba(0, 212, 212, 0.205);
  width: 100%;
}
.button.opensea:hover {
  /* color: black; */
  background: rgba(0, 212, 212, 0.336);
}
.info {
  padding: 10px;
  width: 100%;
}

.into-mintprice {
  padding-top: 5px;
}

.info-name {
  font-weight: bold;
  letter-spacing: 0.5px;
  padding-bottom: 5px;
}
</style>
