<template>
  <transition name="fade">
    <div class="modal" v-if="show">
        This site uses cookies
        <button type="button" class="modal-button" @click="closeModal()">
          <svg class="modal-x-svg" xmlns="http://www.w3.org/2000/svg" viewBox="-25 -25 80 80" width="50" overflow="visible" stroke="black" stroke-width="10" stroke-linecap="round">
            <line x1="0" y1="0" x2="50" y2="50" />
            <line x1="50" y1="0" x2="0" y2="50" />
          </svg>
        </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CookiesAlert',
  data () {
    return {
      show: true
    }
  },
  methods: {
    closeModal () {
      this.show = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal {
  right: -50px;
  bottom: 110px;
  position: fixed;
  width: 150px;
  text-align: left;
  font-size: 0.8em;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  background: rgb(255, 255, 255);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  zoom: 1.005;
}
.modal-button {
  border: 0px;
  background: rgba(0, 0, 0, 0);
}
.modal-button:hover {
  cursor: pointer;
}
.modal-x-svg {
  width: 8px;
  height: auto;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
