<template>
  <div id="collection-feed">
    <div class="pagination-container top">
      <div class="pagination-button-container">
        <button
          class="button outer"
          v-if="page > 1"
          v-bind:disabled="paginating"
          v-on:click.prevent="loadWeb3TokenData('left', 5)"
        >
          ⇐
        </button>
      </div>
      <div class="pagination-button-container">
        <button
          class="button inner"
          v-if="page > 1"
          v-bind:disabled="paginating"
          v-on:click.prevent="loadWeb3TokenData('left', 1)"
        >
          ←
        </button>
      </div>
      <div class="pagination-button-container">
        <button
          class="button inner"
          v-bind:disabled="paginating"
          v-if="!lastPage"
          v-on:click.prevent="loadWeb3TokenData('right', 1)"
        >
          →
        </button>
      </div>
      <div class="pagination-button-container">
        <button
          class="button outer"
          v-bind:disabled="paginating"
          v-if="!lastPage"
          v-on:click.prevent="loadWeb3TokenData('right', 5)"
        >
          ⇒
        </button>
      </div>
    </div>
    <div class="collection-container-grid">
      <div class="collection-item-grid" v-for="i in end - start" :key="i">
        <TokenDisplay
          :tokenData="tokenMetaData[i - 1 + start]"
          :tokenId="tokenData.tokenIds[i - 1 + start]"
          :contractAddress="tokenData.contractAddress"
          :collectionId="collectionId"
          :blockchainId="blockchainId"
          :contract="$props.contract"
          :connectedAccount="$props.connectedAccount"
          :tokenCanMint="tokenCanMint"
          :polygonNetwork="polygonNetwork"
        />
        <!-- :web3TokenData="web3TokenData[i-1+start]" -->
      </div>
    </div>
  </div>
</template>

<script>
// import devApiResp from '@/assets/dev_opensea_api_resp.json'
// import tokenMetaData from "@/assets/token_metadata.json";
import TokenDisplay from "@/components/TokenDisplay.vue";
import $backend from "@/backend.js";

export default {
  name: "Web3CollectionFeed",
  components: {
    TokenDisplay,
  },
  props: {
    tokenData: Object,
    collectionId: String,
    blockchainId: String,
    contract: Object,
    connectedAccount: String,
    tokenCanMint: Boolean,
    refreshWeb3TokenData: Number, // increment to refresh
    polygonNetwork: String,
  },
  methods: {
    async loadWeb3TokenData(direction, numPages) {
      this.paginating = true;
      console.log("loadWeb3TokenData");
      console.log(direction);
      // this.paginate(direction);

      if (!numPages) {
        numPages = 1;
      }

      for (let i = 0; i < numPages; i++) {
        if (direction === "left") {
          if (this.page === 1) {
            break; // at start already
          }
          this.page -= 1;
        } else if (direction === "right") {
          if (this.lastPage) {
            break; // at end already
          }
          this.page += 1;
        }
        this.start = (this.page - 1) * this.pageSize;
        this.end = this.page * this.pageSize;
        if (this.end >= this.$props.tokenData.tokenIds.length) {
          this.end = this.$props.tokenData.tokenIds.length;
          this.lastPage = true;
        } else {
          this.lastPage = false;
        }
      }

      // skip loading data if direction is passed and page already loaded
      // if direction is not passed then assume we are refreshing data on current page
      if (this.pagesLoaded.includes(this.page) && direction) {
        this.paginating = false;
        return;
      }
      this.pagesLoaded.push(this.page);

      this.tokenMetaData = [];
      for (let i = 0; i < this.tokenData.tokenIds.length; i++) {
        let networkFolder = this.$props.polygonNetwork === "testnet" ? 'testnet-metadata' : 'metadata';
        this.tokenMetaData.push(await $backend.GET(`/${networkFolder}/${this.$props.collectionId}/token/${this.tokenData.tokenIds[i]}`));
      }

      if (this.$props.contract && (this.$props.collectionId === "atomic-bohr-diagrams")) {
        for (let i = this.start; i <= this.end; i++) {
          let numMinted = await $backend.web3GetNumMinted(
            this.$props.contract,
            this.tokenData.tokenIds[i],
          );
          this.$set(this.tokenMetaData[i], "num_minted", numMinted);
          let numBurned = await $backend.web3GetNumBurned(
            this.$props.contract,
            this.tokenData.tokenIds[i],
          );
          this.$set(this.tokenMetaData[i], "num_burned", numBurned);
          let mintPrice = await $backend.web3GetMintPrice(
            this.$props.contract,
            this.tokenData.tokenIds[i],
            numMinted
          );
          this.$set(this.tokenMetaData[i], "mint_price", mintPrice);
          // DONE - validate then remove comment
          // TODO line below seems broken
          // what I need here is to call this as batch for range of tokens
          // and get balance of each. then map those onto the tokenIds
          // also needs to work for decays
          // let numOwned = await $backend.web3ERC1155GetAccountBalance(this.$props.contract, this.$props.connectedAccount)
          // this.$set(this.tokenMetaData[i], 'num_owned_by_connected_addr', numOwned)
        }
        // let numOwned = await $backend.web3ERC1155GetAccountBalance(
        //   this.$props.contract,
        //   this.$props.connectedAccount,
        //   this.start + 1,
        //   this.end + 1
        // );
        // for (let i = this.start; i <= this.end; i++) {
        //   this.$set(
        //     this.tokenMetaData[i],
        //     "num_owned_by_connected_addr",
        //     numOwned[i]
        //   );
        // }
      }
      this.$emit("tokenDataUpdated");
      this.paginating = false;

      // let url = `https://rinkeby-api.opensea.io/api/v1/assets?asset_contract_address=${this.tokenData.contractAddress}`
      // for (let i = this.start; i <= this.end; i++) {
      //   url += `&token_ids=${this.tokenData.tokenIds[i]}`
      // }
      // console.log(url)
      // axios.get(url).then(response => this.liveTokenData.push(...response.data.assets))

      // this.liveTokenData = devApiRes
    },
    // paginate(direction) {
    //   if (direction === "left") {
    //     this.page -= 1;
    //   } else if (direction === "right") {
    //     this.page += 1;
    //   }
    //   this.start = (this.page - 1) * this.pagesize;
    //   this.end = this.page * this.pagesize;
    //   if (this.end >= this.$props.tokendata.tokenids.length) {
    //     this.end = this.$props.tokendata.tokenids.length;
    //     this.lastpage = true;
    //   } else {
    //     this.lastpage = false;
    //   }
    //   console.log(this.start)
    //   console.log(this.end)
    //   console.log(this.lastpage)
    // },
  },
  mounted() {
    // this.loadLocalTokenData()
    // this.loadLiveTokenData()
  },
  watch: {
    tokenData: async function () {
      await this.loadWeb3TokenData();
    },
    contract: async function () {
      await this.loadWeb3TokenData();
    },
    polygonNetwork: async function() {
      // clear the collection feed
      this.tokenMetaData = [];
      this.page = 1;
      this.start = 0;
      this.end = 0;
      this.pageSize = 6;
      this.lastPage = false;
      this.pagesLoaded = [];
      await this.loadWeb3TokenData();
    },
    refreshWeb3TokenData: async function () {
      await this.loadWeb3TokenData();
    },
    collectionId: async function () {
      // clear the collection feed
      this.tokenMetaData = [];
      this.page = 1;
      this.start = 0;
      this.end = 0;
      this.pageSize = 6;
      this.lastPage = false;
      this.pagesLoaded = [];
      await this.loadWeb3TokenData();
    },
  },
  created() {
    // this.$parent.$on('tokenDataLoaded', this.loadLiveTokenData)
    // this.$parent.$on('web3Available', this.loadLiveTokenData)
  },
  data() {
    return {
      // web3TokenData: [],
      tokenMetaData: [],
      page: 1,
      start: 0,
      end: 0,
      paginating: false,
      pageSize: 6,
      lastPage: false,
      pagesLoaded: [],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#collection-feed {
  margin-top: 20px;
  padding-bottom: 30px;
}

.collection-container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 10px;
  justify-content: center;
  align-content: flex-start;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
  row-gap: 80px;
  margin-bottom: 60px;
}
.collection-item-grid {
  height: 370px;
  width: 300px;
}

.collection-container-flex {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  justify-content: flex-start;
  row-gap: 10px;
  margin: 0 auto;
}

.collection-item-flex {
  flex: 1 0 calc(256 - 10px);
}

.pagination-container {
  display: flex;
  justify-content: center;
  width: 70px;
  max-width: 50%;
  margin: auto;
}
.pagination-container.top {
}
.pagination-container.bottom {
  padding-top: 20px;
}

.button {
  padding: 0 3px;
  display: inline-block;
  outline: none;
  margin-top: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #444;
  /* font-size: 0.9em; */
  background-color: white;
  transition: all 0.2s;
}

.button.inner {
  padding: 0.5em 1em;
}

.button.outer {
  padding: 0.3em 1em;
}

.pagination-button-container {
  padding: 0 3px;
}

.button:hover:not([disabled]) {
  background-color: #ccc;
  color: black;
}

.button:active:not([disabled]),
.button.selected:not([disabled]) {
  background-color: #444;
  border: 1px solid #444;
}


button:disabled {
  background-color: white;
  color: #444;
  opacity: 50%;
  border: 1px solid white;
}

</style>
