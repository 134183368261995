<template>
  <div>
    <div class="info-header">How to mint Atomic NFTs</div>
    <div class="info-text">
      <hr>
      <p>
        See <a href="https://twitter.com/AtomicNFTs_xyz/status/1513338052101525509">this tweet thread</a> for a full guide to minting Atomic NFTs.
        <br>
        <br>
        Atomic Bohr Diagrams can be minted from <a href="https://atomicnfts.xyz/atomic-bohr-diagrams">this page on atomicnfts.xyz</a> by connecting your Metmask wallet. You will need a Polygon account with MATIC tokens to pay for the mint.
        <br>
        <br>
        Once you have an Atomic Bohr Diagram, you can burn it in order to mint an Atomic Decay from <a href="https://atomicnfts.xyz/atomic-decays">this page on atomicnfts.xyz</a>. There is no fee for doing this aside from the small gas cost to process the transaction on Polygon.
      </p>
    </div>
    <div class="info-header">About {{ collectionName }}</div>
    <div class="info-text">
      <hr>
      <p>{{ collectionDesc }}</p>
    </div>
    <div class="info-header">What are NFTs?</div>
    <div class="info-text">
      <hr>
      <p>NFTs are tokenized data that live on a public blockchain like Polygon or Ethereum. NFT digital art can be purchased, owned and sold like traditional art.</p>
    </div>
    <div class="info-header">Why use Polygon instead of Ethereum?</div>
    <div class="info-text">
      <hr>
      <p>
        Polygon is one of the most popular Ethereum layer-2 sidechains.
        By minting NFTs on Polygon, digital art can be bought and sold more freely without unnecessarily high transaction fees of the Ethereum main chain, while still having very strong security guarantees.
        <br>
        <br>
        {{ collectionName }} NFTs on Polygon are the canonical Ethereum NFT assets. They will never be minted on the Ethereum main chain or any other Ethereum layer-2 blockchain.
      </p>
    </div>
    <div class="info-header">How are Atomic NFTs liscenced?</div>
    <div class="info-text">
      <hr>
      <p>
        Atomic NFTs are liscened as <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">CC BY-NC-SA 4.0</a>. This includes all digital assets associated with Atomic Bohr Diagram NFTs and Atomic Decay NFTs, such as HTML art, PNG art, and JSON metadata.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollectionHeader',
  props: {
    collectionName: String,
    collectionDesc: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.info-header {
  width: 500px;
  max-width: 70%;
  font-size: 20px;
  margin: auto;
  text-align: center;
  padding: 20px 0 0 0;
}

.info-text {
  width: 500px;
  max-width: 70%;
  /* font-size: 12px; */
  margin: auto;
  text-align: left;
  padding-bottom: 30px;
}

.info-text p {
  margin: auto 0;
}

hr {
  border: 1px solid black;
}

</style>
