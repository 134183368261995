import { ethers, utils } from "ethers";
import axios from "axios";
import AWS from "aws-sdk";
import AtomicBohrDiagramsABI from "@/assets/AtomicBohrDiagramsABI.json";
import AtomicDecaysABI from "@/assets/AtomicDecaysABI.json";

export default {
  async GET(url) {
     let response = await axios.get(url);
     return response.data;
  },
  async web3ConnectWallet(polygonNetwork) {
    let msg;
    try {
      const { ethereum } = window;
      // console.log("web3ConnectWallet ethereum")
      // console.log(ethereum)
      if (!ethereum) {
        msg = "Metamask is required to use this DApp";
        console.error(msg);
        const account = null;
        return { account, msg };
      }
      let account = await this.web3GetAccounts();
      if (account) {
        account = account[0]; // get the first one
      } else {
        account = (await this._web3RequestAccountsAccess())[0]; // get the first one
        // console.log("web3ConnectWallet account")
        // console.log(account)
      }
      // if (!(await this._web3SwapToRinkeby())) {
      let err = await this._web3SwapToPolygon(polygonNetwork);
      if (err !== "") {
        err = await this._web3AddPolygonNetwork(polygonNetwork);
        if (err) {
          msg = "Add wallet network for Polygon to use this DApp";
          console.error(msg);
        } else {
          err = await this._web3SwapToPolygon(polygonNetwork);
        }
      }
      if (err !== "") {
        msg = "Set wallet network to Polygon to use this DApp";
        console.error(msg);
      }
      return { account, msg };
    } catch (err) {
      console.error(err);
      let account;
      msg = err;
      return { account, err };
    }
  },
  async web3GetAccounts() {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: "eth_accounts" });
    // console.log("web3GetAccounts ")
    // console.log(accounts)
    if (accounts.length !== 0) {
      return accounts;
    } else {
      return null;
    }
  },
  async web3GetContracts(atomicBohrDiagramsAddress, atomicDecaysAddress) {
    const { ethereum } = window;
    let contracts = {};
    try {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const bohrDiagramsContract = new ethers.Contract(
        atomicBohrDiagramsAddress,
        // AtomicBohrDiagrams.abi,
        AtomicBohrDiagramsABI,
        signer
      );
      const decaysContract = new ethers.Contract(
        atomicDecaysAddress,
        // AtomicDecays.abi,
        AtomicDecaysABI,
        signer
      );
      contracts["atomic-bohr-diagrams"] = bohrDiagramsContract;
      contracts["atomic-decays"] = decaysContract;
      return contracts;
    } catch (err) {
      console.log("error in web3GetContracts");
      console.log(err);
      return contracts;
    }
  },
  async web3GetAccountBalances(erc1155Contract, erc721Contract, account) {
    let bohrAtomsBalance = await this.web3ERC1155GetAccountBalance(
      erc1155Contract,
      account
    );
    console.log('bohr', bohrAtomsBalance);
    let decayAtomsBalance = await this.web3ERC721GetAccountBalance(erc721Contract, account);
    console.log('decay',decayAtomsBalance);
    let balance = bohrAtomsBalance + decayAtomsBalance;
    console.log('balance',balance);
    return [balance, bohrAtomsBalance, decayAtomsBalance];
  },
  async web3ERC721GetAccountBalance(contract, account) {
    const balanceOf = await contract.balanceOf(account);
    let balance = balanceOf.toNumber();
    return balance;
  },
  async web3ERC1155GetAccountBalance(
    contract,
    account,
    firstTokenId,
    lastTokenId
  ) {
    let balance = 0;
    let accounts = [];
    let tokenIds = [];
    if (firstTokenId && !lastTokenId) {
      // Get a single token balance
      const balanceOf = await contract.balanceOf(account, firstTokenId);
      balance = balanceOf.toNumber();
      return balance;
    }
    // Get all token IDs unless lastTokenId is set
    let startId = 1;
    let endId = 118;
    if (lastTokenId) {
      // Get range of token IDs
      startId = firstTokenId;
      endId = lastTokenId;
    }
    for (let i = startId; i <= endId; i++) {
      accounts.push(account);
      tokenIds.push(i);
    }
    const balanceOfBatch = await contract.balanceOfBatch(accounts, tokenIds);
    for (let i = 0; i < balanceOfBatch.length; i++) {
      balance += balanceOfBatch[i].toNumber();
    }
    return balance;
  },
  async web3GetMintPrice(bohrDiagramsContract, tokenId, numMinted) {
    let mintPrice;
    let mintPriceModifier;
    if (numMinted === 0) {
      mintPriceModifier = await bohrDiagramsContract.mintPriceModifier();
      mintPrice = mintPriceModifier * 500000000000000;
    } else {
      mintPrice = await bohrDiagramsContract.lastTokenMintPrice(tokenId);
      if (numMinted % 10 === 0) {
        // lastTokenMintPrice is yet to be updated with current minting price
        mintPrice *= 2;
      }
    }
    return (mintPrice / 1e18).toString();
  },
  async web3Mint(contract, collectionId, tokenId, mintPrice) {
    let tx;
    if (collectionId == "atomic-bohr-diagrams") {
      let overrides = {
        value: ethers.utils.parseEther(mintPrice), // convert Ether to Wei, must pass in a string
      };
      try {
        tx = await contract.mint(tokenId, overrides);
      } catch (err) {
        console.error("Mint failed");
        console.error(err);
        if (err.message.includes('transaction underpriced')) {
          return 'transaction underpriced';
        }
        else if (err.data) {
          if (err.data.message) {
            if (err.data.message.includes('insufficient funds')) {
              return 'insufficient funds';
            }
          }
        }
        return;
      }
    } else if (collectionId == "atomic-decays") {
      try {
        tx = await contract.mint(tokenId);
      } catch (err) {
        console.error("Mint failed");
        console.error(err);
        if (err.message.includes('transaction underpriced')) {
          return 'transaction underpriced';
        }
        return;
      }
    } else {
      console.error("Not implemented: " + collectionId);
      return;
    }
    return tx;
  },
  generateDecayNFT(hash) {
    AWS.config.region = "us-east-1";
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: "us-east-1:f8374dfd-e114-4add-a3d8-33c2fbf63fd3",
    });
    var lambda = new AWS.Lambda({
      region: "us-east-1",
      apiVersion: "2015-03-31",
    });
    var args = {
      FunctionName: "atomicnfts-atomic-decay-mint",
      Payload: JSON.stringify({
        mint_tx_hash:
          hash,
      }),
    };
    var res;

    lambda.invoke(args, function (error, data) {
      console.log(error, data);
      if (error) {
        console.error(error);
      } else {
        res = JSON.parse(data.Payload);
        console.log(res);
      }
    });
  },
  async web3GetNumMinted(bohrDiagramsContract, tokenId) {
    let res;
    try {
      res = await bohrDiagramsContract.tokenMints(tokenId);
      res = parseInt(res);
    } catch (err) {
      console.error(err);
      res = null;
    }
    return res;
  },
  async web3GetNumBurned(bohrDiagramsContract, tokenId) {
    let res;
    try {
      res = await bohrDiagramsContract.tokenBurns(tokenId);
      res = parseInt(res);
    } catch (err) {
      console.error(err);
      res = null;
    }
    return res;
  },
  async _web3AddPolygonNetwork(polygonNetwork) {
    const { ethereum } = window;
    let polygonChainId;
    let networkParams;

    if (polygonNetwork === "mainnet") {
      polygonChainId = utils.hexValue(137);
    } else if (polygonNetwork === "testnet") {
      polygonChainId = utils.hexValue(80001);
    } else {
      console.error("No chain ID for network: " + polygonNetwork)
    }

    if (polygonNetwork === "mainnet") {
      networkParams = {
        chainId: polygonChainId, // '0x89'
        chainName: "Polygon Mainnet", 
        nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
        rpcUrls: ["https://polygon-rpc.com/"],
        blockExplorerUrls: ["https://www.polygonscan.com/"],
      }
    } else if (polygonNetwork === "testnet") {
      networkParams = {
        chainId: polygonChainId, // '0x13881'
        chainName: "Polygon Mumbai Testnet",
        nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
        rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      }
    }
    try {
      await ethereum.request({
        method: "wallet_addEthereumChain",
        params: [networkParams],
      });
      return "";
    } catch (err) {
      console.error(err);
      return err;
    }
  },
  async _web3SwapToPolygon(polygonNetwork) {
    const { ethereum } = window;
    let polygonChainId;
    if (polygonNetwork === "mainnet") {
      polygonChainId = utils.hexValue(137);
    } else if (polygonNetwork === "testnet") {
      polygonChainId = utils.hexValue(80001);
    } else {
      console.error("No chain ID for network: " + polygonNetwork)
    }
    let chainId = await ethereum.request({ method: "eth_chainId" });
    if (chainId !== polygonChainId) {
      try {
        await ethereum.request({
          method: "wallet_switchethereumchain",
          params: [{ chainid: polygonChainId }],
        });
        return true;
      } catch (err) {
        console.error(err);
        return err;
      }
    }
  },
  async _web3SwapToRinkeby() {
    const { ethereum } = window;
    const rinkebyChainId = "0x4";
    let chainId = await ethereum.request({ method: "eth_chainId" });
    if (chainId !== rinkebyChainId) {
      try {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: rinkebyChainId }],
        });
      } catch (err) {
        console.error(err);
        return false;
      }
    }
    return true;
  },
  async _web3RequestAccountsAccess() {
    const { ethereum } = window;
    // console.log("_web3RequestAccountsAccess ")
    // console.log(accounts)
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    return accounts;
  },
};
