<template>
  <div class="main">
    <a href="https://atomicnfts.xyz/">
      <img
        class="header-logo"
        src="https://atomicnfts.xyz/atomic_nfts_transparent.png"
        alt="AtomicNFTs.xyz"
      />
    </a>
    <h1>Legal Disclaimer for AtomicNFTs</h1>
    <div class="disclaimer-content">
      <p>
        The following legal disclaimer outlines important information and terms
        that users should carefully consider before minting (creating)
        Non-Fungible Tokens (NFTs) on any platform or blockchain. By proceeding
        to mint NFTs, you acknowledge and accept the terms and conditions set
        forth in this disclaimer.
      </p>

      <h3>1. Ownership and Rights:</h3>
      <p>
        Minting an NFT represents a digital record of ownership for a specific
        piece of digital content. However, it does not grant ownership of the
        underlying intellectual property rights, copyrights, trademarks, or any
        other rights associated with the content itself. The original creator of
        the content retains all such rights, unless otherwise explicitly stated.
      </p>

      <h3>2. Legal Compliance:</h3>

      <p>
        Users are responsible for ensuring that the content they mint as NFTs
        complies with all applicable laws, regulations, and intellectual
        property rights. Unauthorized use of copyrighted material, trademarks,
        or any other protected content may result in legal actions, including
        takedown requests and potential lawsuits.
      </p>

      <h3>3. Platform Terms and Fees:</h3>

      <p>
        Different platforms have their own terms of service and fees associated
        with minting and trading NFTs. It is essential to review and understand
        the terms of the platform you are using, as well as any fees that may be
        incurred during the minting, listing, and selling processes.
      </p>

      <h3>4. Third-Party Content:</h3>
      <p>
        If your NFT includes or references third-party content, you must obtain
        proper permissions or licenses from the content owner before minting.
        Failure to do so may result in legal claims and the removal of your NFTs
        from circulation.
      </p>

      <h3>5. Market Value and Volatility:</h3>

      <p>
        The value of NFTs can be highly volatile and may fluctuate drastically.
        Minting an NFT does not guarantee its future market value, and users
        should be aware of the potential risks associated with investing in or
        trading NFTs.
      </p>

      <h3>6. Privacy and Data Security:</h3>
      <p>
        Certain personal information may be required during the minting process,
        such as wallet addresses. Users are advised to exercise caution and
        ensure that they are using secure and reputable platforms to prevent
        unauthorized access to their personal data.
      </p>

      <h3>7. Smart Contracts and Technical Risks:</h3>

      <p>
        NFTs are often implemented using smart contracts, which are code-based
        agreements. Users should be aware of potential vulnerabilities in these
        contracts that could lead to hacks, loss of assets, or other technical
        issues.
      </p>

      <h3>8. Tax Implications:</h3>

      <p>
        The minting, buying, and selling of NFTs may have tax implications
        depending on your jurisdiction. Users are encouraged to consult with tax
        professionals to understand their tax obligations.
      </p>

      <h3>9. Legal Dispute Resolution:</h3>

      <p>
        Any legal disputes arising from the minting, ownership, or trading of
        NFTs may be subject to the terms and jurisdiction specified by the
        platform's terms of service.
      </p>

      <h3>10. No Legal Advice:</h3>

      <p>
        This disclaimer does not constitute legal advice. Users are strongly
        encouraged to consult with legal professionals for advice tailored to
        their specific situations before minting NFTs.
      </p>

      <p>
        By proceeding with the minting process, you acknowledge that you have
        read, understood, and agreed to the terms and conditions set forth in
        this legal disclaimer. If you do not agree with these terms, refrain
        from minting NFTs.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LegalDisclaimer",
};
</script>

<style scoped>
.main {
  width: 500px;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
}
.header-logo {
  width: 400px;
  max-width: 60%;
}
.disclaimer-content {
  text-align: left;
  padding-bottom: 20px;
}
</style>

