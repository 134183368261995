<template>
  <div id="footer">
    <hr />
    <div class="banner-container">
      <div class="banner-item left">
        <a :href="contractAddressUrl()" target="_blank">{{ contractAddressAnchor() }}</a>
      </div>
      <div class="banner-item center"></div>
      <div class="banner-item right">
        © {{ new Date().getFullYear() }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    contractAddress: String,
    polygonNetwork: String,
  },
  methods: {
    contractAddressUrl() {
      let networkSubdomain = this.$props.polygonNetwork === "testnet" ? "mumbai." : "";
      return `https://${networkSubdomain}polygonscan.com/address/${this.$props.contractAddress}`;
    },
    contractAddressAnchor() {
      return `${this.$props.contractAddress.slice(
        0,
        4
      )} ... ${this.$props.contractAddress.slice(-4)}`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#footer {
  padding-top: 50px;
}

.banner-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
}

.banner-item {
  flex-grow: 0;
}

.left {
  text-align: left;
  flex-grow: 1;
  flex-basis: 0;
}
.center {
  text-align: center;
  /* font-size: 16px; */
  letter-spacing: 3px;
}

.right {
  text-align: right;
  flex-grow: 1;
  flex-basis: 0;
}

hr {
  border: 1px solid black;
}
</style>
