<template>
  <div :class="mintButtonClassName()" v-on:click="mintTokenButton()">
    <div class="mint-text">{{ mintMsg }}</div>
  </div>
</template>

<script>
import $backend from "@/backend.js";

export default {
  name: "MintAtomicBohrDiagramButton",
  props: {
    tokenId: String,
    collectionId: String,
    contract: Object,
  },
  methods: {
    async sleep(ms) {
      return await new Promise((resolve) => setTimeout(resolve, ms));
    },
    mintButtonClassName() {
      if (this.isMinting) {
        return "button mint minting";
      } else if (this.isMinted) {
        return "button mint minted";
      } else {
        return "button mint new";
      }
    },
    async mintTokenButton() {
      if (this.isMinting || this.isMinted) {
        // window.open(`https://rinkeby.etherscan.io/tx/${this.tx.hash}`)
        window.open(`https://polygonscan.com/tx/${this.tx.hash}`);
      } else {
        if (!this.$props.contract) {
          await this.$parent.$parent.$parent.connectWallet();
        }
        // await this.testMintToken();
        await this.mintToken();
      }
    },
    async testMintToken() {
      this.isMinting = true;
      this.mintMsg = "View Txn on polygonscan ⏳ Waiting for Confirmations";
      await this.sleep(5000);
      this.isMinting = false;
      this.isMinted = true;
      this.mintMsg = "View Txn on polygonscan ✔️ Confirmed!";
    },
    async mintToken() {
      const numMinted = await $backend.web3GetNumMinted(
        this.$props.contract,
        this.$props.tokenId
      );
      const mintPrice = await $backend.web3GetMintPrice(
        this.$props.contract,
        this.$props.tokenId,
        numMinted
      );
      if (mintPrice != this.$props.mintPrice) {
        // data is outdated, update it
        // await this.$parent.$parent.loadWeb3TokenData(); // does same as line below
        await this.$parent.$parent.$parent.triggerRefreshWeb3TokenData();
      }
      this.tx = await $backend.web3Mint(
        this.$props.contract,
        this.$props.collectionId,
        this.$props.tokenId,
        mintPrice
      );
      console.log(this.tx);
      if (!this.tx) {
        let msg = "Mint failed ✕";
        this.mintMsg = msg;
        console.error(msg);
        return;
      } else if (this.tx === 'insufficient funds') {
        let msg = "Insufficient funds to mint 💰 Not enough MATIC.";
        this.mintMsg = msg;
        console.error(msg);
        return;
      } else if (this.tx === 'transaction underpriced') {
        let msg = "Mint failed ✕ Not enough gas ⛽ Please try again.";
        this.mintMsg = msg;
        console.error(msg);
        return;
      } else if (!this.tx.from) {
        let msg = "Mint failed ✕";
        this.mintMsg = msg;
        console.error(msg);
        return;
      }
      this.isMinting = true;
      this.mintMsg = "View Txn on polygonscan ⏳ Waiting for Confirmations";
      const receipt = await this.tx.wait(); // wait for tx to be mined
      console.log(receipt);
      if (!receipt.status) {
        let msg = "Mint failed ✕";
        this.mintMsg = msg;
        console.error(msg);
      }
      await this.$parent.$parent.$parent.triggerRefreshWeb3TokenData();
      await this.$parent.$parent.$parent.setAccountBalance(this.tx.from);
      this.isMinting = false;
      this.isMinted = true;
      this.mintMsg = "View Txn on polygonscan ✔️ Confirmed!";
    },
  },
  data() {
    return {
      mintMsg: "Mint",
      isMinting: false,
      isMinted: false,
      tx: null,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button.mint {
  cursor: default;
  border: 1px solid #ccc;
  width: 100%;
  vertical-align: middle;
}
.button.mint:hover {
  background: rgba(0, 212, 0, 0.336);
}
.button.mint.new {
  background: rgba(0, 212, 0, 0.205);
}
.button.mint.minting {
  background: rgba(218, 204, 8, 0.384);
}
.button.mint.minted {
  background: rgba(0, 212, 0, 0.205);
}
</style>
